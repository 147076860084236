<template>
    <div class="main-wrapper">
        <div class="search-box">
            <el-input
                    placeholder="请输入内容"
                    v-model="keywords"
                    class="search-input"
                    @keyup.enter.native="practiceSearch">
                <el-button slot="append" icon="el-icon-search" @click="practiceSearch"></el-button>
            </el-input>
        </div>
        <el-table :data="examList" class="customTable" style="width: 100%; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="op_name" label="题文" align="left" width="400">
                <template slot-scope="scope">
                    <span class="op-name">{{scope.row.op_name}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="category_name" label="试题分类"></el-table-column>
            <el-table-column></el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="viewDetail(scope.row)">查看详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {checkCenter_distributionOpt} from '@/utils/apis'

    export default {
        name: "OperationPaper",
        data() {
            return {
                examList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                keywords: '',
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                    paging: 1
                }
                if (this.keywords) {
                    params.keyword = this.keywords
                }
                checkCenter_distributionOpt(params).then(res => {
                    this.examList = res.data.list
                    this.listPages.total = res.data.total
                })
            },
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            practiceSearch() {
                this.listPages.currentPageNum = 1
                this.getList();
            },
            viewDetail(row) {
                let type = '';
                switch (row.exam_module_id) {
                    // 商品信息采集与处理
                    case 8:
                    case 9:
                    case 10:
                        type = 'goods_info_collect'
                        break;
                    // 网店管理
                    case 15:
                    case 16:
                    case 18:
                        type = 'shop_management'
                        break;
                    // 客服
                    case 21:
                        type = 'store_customer_service'
                        break;
                    // 数据收集与清洗
                    case 22:
                    case 23:
                        type = 'data_capture_clean'
                        break;
                    // 网店装修
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                        type = 'shop_decoration'
                        break;
                    // 网络推广
                    case 19:
                    case 20:
                        type = 'network_promotion'
                        break;
                    //网络客户
                    case 42:
                    case 43:
                        type = 'store_customer_service '
                        break;
                    // 三级 网络营销
                    case 33:
                    case 34:
                    case 35:
                    case 36:
                    case 37:
                    case 38:
                        type = 'network_marketing'
                        break;
                    // 三级 网络营销
                    case 44:
                    case 45:
                    case 46:
                        type = 'data_analysis_application'
                        break;
                    //  网上交易管理
                    case 39:
                    case 40:
                    case 41:
                        type = 'online_transaction_management'
                        break;
                    // 三级 网店视觉设计
                    case 30:
                    case 31:
                    case 32:
                        type = 'store_visual_design'
                        break;
                }
                this.$router.push({
                    path: '/examPaperManage/operationPaperDetail',
                    query: {
                        op_id: row.op_id,
                        type: type
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }

    .a-link {
        color: inherit;
    }

    .search-input {
        width: 300px !important;
        margin-bottom: 20px;

        ::v-deep .el-input-group__append {
            padding: 0 8px;
            background: #2dc079;

            .el-button {
                i {
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }

    .op-name {
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>